import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  > h2 {
    padding: 20px 10px 10px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #dbe1e9;
  border-radius: 5px;
  padding: 20px 30px;
`;

export const InputButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > button {
    border: 0;
    border-radius: 5px;
    height: 42px;
    width: 50px;
    background: none;
    color: #cbd0d8;
    transition: all 1s;
    &:hover {
      background: ${darken(0.1, '#f2f5fc')};
      color: ${darken(0.1, '#1789dd')};
    }
  }
`;

export const Footer = styled.div``;

Footer.Action = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 5px;

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 0;
    height: 42px;
    min-width: 150px;

    > svg {
      margin-right: 5px;
    }

    & + button {
      margin-left: 10px;
    }

    &#btn-new {
      background: #ff6d3c;
      color: #fff;
      transition: background 1s;

      &:hover {
        background: ${darken(0.05, '#ff6d3c')};
      }
    }

    &#btn-upload {
      background: none;
      color: #1789dd;
      font-weight: normal;
      transition: color 1s;

      &:hover {
        color: ${darken(0.1, '#1789dd')};
      }
    }
  }
`;
