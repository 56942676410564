import React, { useRef } from 'react';

import { DebounceInput as ReactDebounceInput } from 'react-debounce-input';
import PropTypes from 'prop-types';

import { InputWrapper } from '../Form';

function DebounceInputDefault({
  name,
  label,
  width,
  flexDirection,
  isHidden,
  debounceTimeout,
  ...rest
}) {
  const inputRef = useRef(null);

  return (
    <InputWrapper
      width={width}
      isHidden={isHidden}
      flexDirection={flexDirection}
    >
      {label && <label htmlFor={name}>{label}</label>}

      <ReactDebounceInput
        ref={inputRef}
        debounceTimeout={debounceTimeout}
        {...rest}
      />
    </InputWrapper>
  );
}

DebounceInputDefault.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  width: PropTypes.number,
  flexDirection: PropTypes.string,
  isHidden: PropTypes.bool,
  debounceTimeout: PropTypes.number,
};

DebounceInputDefault.defaultProps = {
  label: null,
  width: null,
  flexDirection: 'column',
  isHidden: false,
  debounceTimeout: 500,
};

export default DebounceInputDefault;
