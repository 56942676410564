import { unparse } from 'papaparse';

import { AppError } from '../errors/AppError';

import api from '../services/api';

export const opExport = {
  CATALOGO: 'catalogo',
  CATALOGO_NIVEL: 'catalogo-nivel',
  CATALOGO_PAGINA: 'pagina-catalogo',
  CATALOGO_ITEMPAGINA: 'catalogo-item',
  CATALOGO_SERIE: 'catalogo-serie',
  PAGINA_ITEMPAGINA: 'pagina-item',
  CONJUNTO: 'conjunto-item-pagina',
  FABRICANTE: 'fabricante',
  MODELO: 'modelo',
  MATERIAL: 'material',
  MATERIAL_ERP: 'material-erp',
  MATERIAL_FABRICANTE: 'material-fabricante',
  EMPRESA: 'empresa',
  EMPRESA_USUARIO: 'empresa-usuario',
  EMPRESA_PARAMETRO: 'empresa-parametro',
  EMPRESA_SISTEMA: 'empresa-sistema',
  EMPRESA_LOCAL: 'empresa-local',
  EMPRESA_CATALOGO: 'empresa-catalogo',
  EMPRESA_MOBILIDADE: 'empresa-mobilidade',
  EMPRESA_CATALOGO_SISTEMA_LOCAL: 'empresa-catalogo-sistema-local',
  EMPRESA_MODELO: 'empresa-modelo',
  USUARIO: 'usuario',
  USUARIO_LOGIN: 'usuario-login',
};

export const opImport = {
  PAGINA_ITEMPAGINA: 'pagina-item',
  CATALOGO_ITEMPAGINA: 'catalogo-item',
  CATALOGO_NIVEL: 'catalogo-nivel',
  CATALOGO_SERIE: 'catalogo-serie',
  FABRICANTE: 'fabricante',
  MODELO: 'modelo',
  EMPRESA_MENU: 'empresa-menu',
  EMPRESA_SISTEMA: 'empresa-sistema',
  EMPRESA_LOCAL: 'empresa-local',
  EMPRESA_MOBILIDADE: 'empresa-mobilidade',
  EMPRESA_MATERIAL_ERP: 'empresa-material-erp',
};

const toCSV = (fields, data) =>
  unparse(
    {
      fields,
      data,
    },
    {
      encoding: 'ISO-8859-1',
      delimiter: ';',
    }
  );

const criarExportacao = async (exportacao, params = {}) => {
  api
    .get('csv/exportar', {
      params: {
        tipoExportacao: exportacao,
        ...params,
      },
    })
    /** anexa os dados e faz o download do documento */
    .then((response) => {
      const { filename } = response.data;

      const link = document.createElement('a');

      link.setAttribute('href', filename);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((err) => {
      AppError(err);
    });
};

const CSV = {
  criarExportacao,
};

export { CSV, toCSV };
