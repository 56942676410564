export const lightTheme = {
  mode: 'light',
  headerBackground: '#f2f5fc',
  background: '#f2f5fc',
  color: '#2c405a',
  colorSharedButton: '#3b82f6',
  colorMenuBurguer: '#2c405a80',
  colorRequestName: '#79869e', // components/form/style.js
  colorUserName: '#444', // components/form/style.js
  colorForgetPassword: '#ff6d3c', // pages/mobile/usuario/_usuario-login/style.js
  cardBackground: '#fff', // pages/mobile/home/style.js -> cards dos catálogos
  buttonBackground: '#e5e7eb',
  cardBackgroundMaintenance: 'rgba(0, 0, 0, 0.4)', // pages/mobile/home/style.js -> catálogos em manutenção
  cardBackgroundMaintenanceContent: 'rgba(255, 255, 255, 0.92)', // pages/mobile/home/style.js -> catálogos em manutenção
  arrow: '#ccd5ea', // components:>Mobile>MainMenu>ItemMenu>style.js
  boxShadow: '#00000026',
  treeViewLinkColor: '#888', // components/treeview/tree/style.js
  invertCatalogImage: '0',
  backgroundSpanCart: '#fff', // pages/mobile/home/carrinho/style.js
  borderCardCatalogoMobile: '#f8f8f8',
  nameCardCatalogoMobile: '#1f2937',
  contentCardCatalogoMobile: '#4b5563',
  languageCardCatalogoMobile: '#9ca3af',
};

export const darkTheme = {
  mode: 'dark',
  headerBackground: '#020617',
  background: '#0f172a',
  color: '#f1f5f9',
  colorSharedButton: '#3b82f6',
  colorMenuBurguer: '#a1b8ce',
  colorRequestName: '#f1f5f9',
  colorUserName: '#f1f5f9',
  colorForgetPassword: '#f1f5f9',
  cardBackground: '#334155',
  buttonBackground: '#334155',
  cardBackgroundMaintenance: 'rgba(255, 255, 255, 0.92)',
  cardBackgroundMaintenanceContent: 'rgba(0, 0, 0, 0.8)',
  arrow: '#6f85ab',
  boxShadow: 'rgba(0, 0, 0, 0.5)',
  treeViewLinkColor: '#ccc',
  invertCatalogImage: '1',
  backgroundSpanCart: '#000',
  borderCardCatalogoMobile: '#334155',
  titleCardCatalogoMobile: '#f1f5f9',
  contentCardCatalogoMobile: '#f1f5f9',
  languageCardCatalogoMobile: '#f1f5f9',
};
