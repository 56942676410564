import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { InputWrapper } from '../Form';

function InputDefault({
  name,
  label,
  width,
  flexDirection,
  isHidden,
  customPagination,
  withoutWrapper,
  ...rest
}) {
  const inputRef = useRef(null);

  return (
    <>
      {withoutWrapper ? (
        <input ref={inputRef} {...rest} />
      ) : (
        <InputWrapper
          width={width}
          isHidden={isHidden}
          flexDirection={flexDirection}
          customPagination={customPagination}
        >
          {label && <label htmlFor={name}>{label}</label>}

          <input ref={inputRef} {...rest} />
        </InputWrapper>
      )}
    </>
  );
}

InputDefault.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  width: PropTypes.number,
  flexDirection: PropTypes.string,
  isHidden: PropTypes.bool,
  customPagination: PropTypes.bool,
  withoutWrapper: PropTypes.bool,
};

InputDefault.defaultProps = {
  label: null,
  width: null,
  flexDirection: 'column',
  isHidden: false,
  customPagination: false,
  withoutWrapper: false,
};

export default InputDefault;
