import signInBackground from '../assets/sign-in-background.png';
import signInBackgroundMegatec from '../assets/sign-in-background-megatec.jpg';

export const backgroundStyles = {
  assiste: `
    background: url(${signInBackground}) no-repeat center; 
    background-size: contain;
    margin: 5px;
    `,
  megatec: `
    background: url(${signInBackgroundMegatec}) no-repeat center; 
    background-size: cover;
    `,
};
