import React, { useState, useEffect, useCallback, forwardRef } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import history from '../../services/history';

import Tree from './Tree';
// import { Form } from '../Form';
// import DebounceInput from '../Form/Input/Debounce';
import DebounceInputDefault from '../DebounceInputDefault';

// import AsyncSelect from '../Form/Input/AsyncSelect';

import { initialParams } from '../../lib/reactRouter';

// import { getArranjo } from '../../lib/asyncUtils';

import { Container, Wrapper } from './styles';

const TreeView = forwardRef(({ data }, refTtreeView) => {
  const location = useLocation();

  const [treeView, setTreeView] = useState(null);

  const carregaTreeView = useCallback(() => {
    const searchParams = [];

    /** pega parâmetros da rota */
    const paramQuery = new URLSearchParams(location.search);
    const paramRoute = {
      ...initialParams,
      ...Object.fromEntries(paramQuery),
    };

    /** processa os parâmetros de sistema, local e pesquisa caso eles existam no primeiro ciclo */
    const {
      FLT_IDS: fltIdS,
      FLT_IDL: fltIdL,
      FLT_IDE: fltIdE,
      FLT_IDA: fltIdA,
      q: fltSearch,
    } = paramRoute;
    if (fltIdS) searchParams.push(`FLT_IDS=${fltIdS}`);
    if (fltIdL) searchParams.push(`FLT_IDL=${fltIdL}`);
    if (fltIdE) searchParams.push(`FLT_IDE=${fltIdE}`);
    if (fltIdA) searchParams.push(`FLT_IDA=${fltIdA}`);
    if (fltSearch) searchParams.push(`q=${fltSearch}`);

    setTreeView({ ...data, searchParams: searchParams.join('&') });
  }, [location, data]);

  useEffect(() => {
    carregaTreeView();
  }, [data, carregaTreeView]);

  // const handleSerieArranjo = useCallback(
  //   (dataArranjo) => {
  //     const params = [];

  //     /** separa valores "serie.arranjo" */
  //     const { value = '' } = dataArranjo || {};
  //     const [serie, arranjo] = value.split('.');

  //     /** pega parâmetros da rota */
  //     const paramQuery = new URLSearchParams(location.search);
  //     const paramRoute = {
  //       ...initialParams,
  //       ...Object.fromEntries(paramQuery),
  //     };

  //     /** processa os parâmetros de sistema e local caso eles existam no primeiro ciclo */
  //     const { FLT_IDS: fltIdS, FLT_IDL: fltIdL } = paramRoute;
  //     if (fltIdS) params.push(`FLT_IDS=${fltIdS}`);
  //     if (fltIdL) params.push(`FLT_IDL=${fltIdL}`);

  //     /** processa arranjo */
  //     if (serie && arranjo) {
  //       params.push(`FLT_IDE=${serie}`);
  //       params.push(`FLT_IDA=${arranjo}`);
  //     }

  //     /** monta rota para redirecionamento */
  //     const routeParam = `?${params.join('&')}`;

  //     history.push(location.pathname + routeParam);
  //   },
  //   [location]
  // );

  const handleSearch = useCallback(
    (search) => {
      const paramSearch = [];

      /** pega parâmetros da rota */
      const paramQuery = new URLSearchParams(location.search);
      const paramRoute = {
        ...initialParams,
        ...Object.fromEntries(paramQuery),
      };

      const {
        FLT_IDS: fltIdS,
        FLT_IDL: fltIdL,
        FLT_IDE: fltIdE,
        FLT_IDA: fltIdA,
      } = paramRoute;
      if (fltIdS) paramSearch.push(`FLT_IDS=${fltIdS}`);
      if (fltIdL) paramSearch.push(`FLT_IDL=${fltIdL}`);
      if (fltIdE) paramSearch.push(`FLT_IDE=${fltIdE}`);
      if (fltIdA) paramSearch.push(`FLT_IDA=${fltIdA}`);
      if (search) paramSearch.push(`q=${search}`);

      history.push(`${location.pathname}?${paramSearch.join('&')}`);
    },
    [location]
  );

  /** rotina recursiva para geração do TreeView */
  const handleTreeView = useCallback(
    (nodes, index = 0, searchLocation = []) => {
      const { idCatalogo = 0 } = treeView || {};

      /** pega parâmetros da rota */
      const paramQuery = new URLSearchParams(location.search);
      const paramRoute = {
        ...initialParams,
        ...Object.fromEntries(paramQuery),
      };

      /** processa os parâmetros de sistema, local e pesquisa caso eles existam no primeiro ciclo */
      if (index === 0) {
        const {
          FLT_IDS: fltIdS,
          FLT_IDL: fltIdL,
          FLT_IDE: fltIdE,
          FLT_IDA: fltIdA,
          q: fltSearch,
        } = paramRoute;
        if (fltIdS) searchLocation.push(`FLT_IDS=${fltIdS}`);
        if (fltIdL) searchLocation.push(`FLT_IDL=${fltIdL}`);
        if (fltIdE) searchLocation.push(`FLT_IDE=${fltIdE}`);
        if (fltIdA) searchLocation.push(`FLT_IDA=${fltIdA}`);
        if (fltSearch) searchLocation.push(`q=${fltSearch}`);
      }

      /** processa os parâmetros da rota para filtrar os níveis */
      const currNivel = Object.keys(paramRoute).reduce(
        (nivAcumulado, nivAtual) => {
          if (nivAtual.startsWith('IDN_')) {
            const [, key] = nivAtual.split('_');
            nivAcumulado.push({
              codigo: Number(key),
              id: Number(paramRoute[nivAtual]),
            });
          }

          return nivAcumulado;
        },
        []
      );

      const lastParam = Object.keys(paramRoute).reduce(
        (paramAcumulado, paramAtual, paramIndex, paramArray) => {
          if (paramIndex === paramArray.length - 1) {
            const [, key] = paramAtual.split('_');
            return {
              codigo: Number(key) || idCatalogo,
              id: Number(paramRoute[paramAtual]),
            };
          }

          return paramAcumulado;
        },
        {}
      );

      /** ordena lista para que as páginas sejam ordenadas por último */
      return nodes
        .sort((a, b) => a.tipo - b.tipo)
        .reduce((accNode, currNode) => {
          const currSearch =
            currNode.tipo === 0
              ? `IDN_${currNode.codigo}=${currNode.id}`
              : `IDP=${currNode.id}`;

          /** define se o nível está aberto */
          const isOpen =
            currNivel.find(
              (n) => n.codigo === currNode.codigo && n.id === currNode.id
            ) &&
            currNode.itens &&
            currNode.itens.length > 0;

          /** verifica se ele está selecionado */
          const { codigo = 0, id = 0 } = lastParam;
          const isSelected = codigo === currNode.codigo && id === currNode.id;

          const search =
            searchLocation.length > 0
              ? `${searchLocation.join('&')}&${currSearch}`
              : `${currSearch}`;

          accNode.push(
            currNode.tipo === 0 ? (
              <Tree
                key={currNode.id}
                name={currNode.descricao}
                searchParams={search}
                defaultOpen={isOpen}
                defaultSelected={isSelected}
              >
                {handleTreeView(currNode.itens, index + 1, search.split('&'))}
              </Tree>
            ) : (
              <Tree
                key={currNode.id}
                name={currNode.descricao}
                searchParams={search}
                defaultOpen={isOpen}
                defaultSelected={isSelected}
              />
            )
          );

          return accNode;
        }, []);
    },
    [location, treeView]
  );

  // {data.arranjoPagina && (
  // <AsyncSelect
  //   id="searchArranjo"
  //   name="searchArranjo"
  //   placeholder="Digite para prequisar um arranjo..."
  //   isClearable
  //   cacheOptions
  //   defaultOptions
  //   loadOptions={(value, cb) => getArranjo({id: data.idCatalogo, value}, cb)}
  //   onChange={(dataArranjo) => handleSerieArranjo(dataArranjo)}
  // />
  // )}

  // console.log(treeView);

  return (
    <Container ref={refTtreeView}>
      {/* <Form autoComplete="off">
        <DebounceInput
          id="search"
          name="search"
          placeholder="Digite para pesquisar..."
          onChange={({ target }) => handleSearch(target.value)}
        />
      </Form> */}
      <DebounceInputDefault
        id="search"
        name="search"
        placeholder="Digite para pesquisar..."
        onChange={({ target }) => handleSearch(target.value)}
      />

      <Wrapper>
        {treeView && (
          <Tree
            name={treeView.nome}
            searchParams={treeView.searchParams}
            defaultOpen
          >
            {handleTreeView(treeView.treeView)}
          </Tree>
        )}
      </Wrapper>
    </Container>
  );
});

TreeView.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default TreeView;
