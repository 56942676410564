import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  MdChevronLeft,
  MdChevronRight,
  MdClose,
  MdHome,
  MdStarOutline,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdListAlt,
  MdPictureAsPdf,
  MdSearch,
  MdOutlineShare,
} from 'react-icons/md';

import { components } from 'react-select';

import { toast } from 'react-toastify';
import api from '../../../../services/api';
import history from '../../../../services/history';

import { AppError } from '../../../../errors/AppError';

import { opEmpresa, opTipoAcao, opTipoAcesso } from '../../../../lib/const';
import { debounce } from '../../../../lib/inputUtils';
import { initialParams } from '../../../../lib/reactRouter';
import { getMaterial, getArranjo } from '../../../../lib/asyncUtils';

import TreeView from '../../../../components/TreeView';

import { Form } from '../../../../components/Form';
import AsyncSelect from '../../../../components/Form/Input/AsyncSelect';
// import Info from '../../../../components/Info';
import Modal from '../../../../components/Modal';

import { MobileListagem } from './Listagem';
import { MobilePagina } from './Pagina';

import {
  Main,
  Container,
  BtnTreeView,
  Menu,
  Content,
  WrapperTitleInfo,
  WrapperTittleInfoSubtitle,
  ContentToolBarHeaderButton,
  BtnFilter,
  WrapperFilterContent,
  WrapperShared,
  // BtnShare,
} from './styles';
import {
  handleHomeFilter,
  handleHomeFilterClear,
} from '../../../../store/modules/homeFilter/actions';
import {
  adicionaCarrinhoArrayRequest,
  sharedManagementSet,
} from '../../../../store/modules/cart/actions';

export function MobileClassificacao() {
  const profile = useSelector((state) => {
    const { userId, name = '', adm, empresa } = state.user.profile || {};
    const { idEmpresa, url: logotipo } = empresa || {};

    return { userId, name, adm, idEmpresa, url: logotipo };
  });

  const carrinho = useSelector((state) =>
    state.cart.items.map((item) => ({
      ...item,
      ...item.materialFabricante,
    }))
  );

  const { id } = useParams();
  const location = useLocation();

  // const { items } = useSelector((state) => state.cart || {});
  const { action, signed, token } = useSelector((state) => state.auth || {});
  const { adm, empresa } = useSelector((state) => state.user.profile || {});
  const { sharedManagement } = useSelector((state) => state.cart || {});

  // console.log(cart);

  // const { items } = cart || {};

  /** referencia para atribuir controles de zoom */
  const zoomInRef = useRef();
  const zoomOutRef = useRef();
  const zoomResetRef = useRef();

  const formRef = useRef();
  const paginaRef = useRef();

  const dispatch = useDispatch();

  const { homeFilter } = useSelector((state) => state || {});

  const [showBreadcrumbs, setShowBreadcrumbs] = useState(false);

  const [menuHidden, setMenuHidden] = useState(false);
  // const [pageMaximize, setPageMaximize] = useState(false);

  const [catalogo, setCatalogo] = useState(null);
  const [paginaCatalogo, setPaginaCatalogo] = useState([]);
  const [controle, setControle] = useState(false);
  const [shownPage, setShownPage] = useState(0);
  // const [nSerie, setNSerie] = useState(null);
  const [catalogoPdf, setCatalogoPdf] = useState(null);

  const [valueAsyncSelect, setValueAsyncSelect] = useState(null);

  const [fmtFilterSearch, setFmtFilterSearch] = useState([]);

  const [urlShared, setUrlShared] = useState(null);

  const handleTreeView = useCallback(() => {
    setMenuHidden((state) => !state);
  }, []);

  useEffect(() => {
    api.get(`/catalogo-navegacao/${id}/pagina`).then((response) => {
      setPaginaCatalogo(response.data);
    });
  }, [id]);

  useEffect(() => {
    if (Array.isArray(homeFilter.paginas) && homeFilter.paginas.length > 0) {
      setFmtFilterSearch(
        paginaCatalogo.filter((i) =>
          homeFilter.paginas.some((j) => i.idPagina === j.idPagina)
        )
      );
    } else {
      setFmtFilterSearch(paginaCatalogo);
    }
  }, [paginaCatalogo, homeFilter]);

  useEffect(() => {
    /** pega parâmetros da rota */
    const paramQuery = new URLSearchParams(location.search);

    const paramRoute = {
      ...initialParams,
      ...Object.fromEntries(paramQuery),
    };

    api
      .get(`/catalogo-navegacao/${id}`, {
        params: {
          ...paramRoute,
        },
      })
      .then((response) => {
        setCatalogo(response.data);

        /** Preenchimento do campos arranjo */
        const { arranjo } = response.data;

        if (arranjo && formRef.current) {
          const valorAtual = formRef.current.getFieldValue('idArranjo');
          if (!valorAtual) {
            formRef.current.setFieldValue('idArranjo', {
              label: `N/S: ${arranjo.numeroSerie} - ${arranjo.nome}`,
              value: `${arranjo.idSerie}.${arranjo.idArranjo}`,
            });
          }
        }
      })
      .catch((err) => {
        AppError(err);
      });
  }, [id, location]);

  useEffect(() => {
    /** pega parâmetros da rota */
    const paramQuery = new URLSearchParams(location.search);

    setControle(paramQuery.has('IDP'));
    if (catalogo && paramQuery.has('IDP')) {
      const page = Number(paramQuery.get('IDP'));

      const { paginas = [] } = catalogo;

      const index = paginas.findIndex((p) => p === page);
      if (index >= 0) setShownPage(index + 1);

      if (homeFilter.value !== null) {
        const indexHomeFilter = fmtFilterSearch.findIndex(
          (p) => p.idPagina === page
        );
        if (indexHomeFilter >= 0) setShownPage(indexHomeFilter + 1);
      }

      setMenuHidden(false);
    }
  }, [location, catalogo, fmtFilterSearch, homeFilter]);

  useEffect(() => {
    api.get(`/catalogo/${id}`).then((response) => {
      setCatalogoPdf(response.data.arquivo.url);
    });
  }, [id]);

  const handlePagina = useCallback(
    (data) => {
      const { value = '' } = data || {};

      const [, idPagina] = value.split('.');

      if (idPagina) {
        const { niveis = [] } =
          paginaCatalogo.find((p) => p.idPagina === Number(idPagina)) || {};

        const searchLocation = [];

        /** processa os níveis da página */
        niveis.forEach((itemNivel) => {
          searchLocation.push(`IDN_${itemNivel.codigo}=${itemNivel.id}`);
        });

        /** processa página */
        searchLocation.push(`IDP=${idPagina}`);

        searchLocation.push(`IDI=${data.value}`);

        /** monta rota para redirecionamento */
        history.push(`${id}?${searchLocation.join('&')}`);
      }
    },
    [id, paginaCatalogo]
  );

  const handleSerieArranjo = useCallback(
    (data) => {
      const params = [];

      /** separa valores "serie.arranjo" */
      const { value = '' } = data || {};
      const [serie, arranjo] = value.split('.');

      /** pega parâmetros da rota */
      const paramQuery = new URLSearchParams(location.search);
      const paramRoute = {
        ...initialParams,
        ...Object.fromEntries(paramQuery),
      };

      /** processa os parâmetros de sistema e local caso eles existam no primeiro ciclo */
      const { FLT_IDS: fltIdS, FLT_IDL: fltIdL } = paramRoute;
      if (fltIdS) params.push(`FLT_IDS=${fltIdS}`);
      if (fltIdL) params.push(`FLT_IDL=${fltIdL}`);

      /** processa arranjo */
      if (serie && arranjo) {
        params.push(`FLT_IDE=${serie}`);
        params.push(`FLT_IDA=${arranjo}`);
      }

      /** monta rota para redirecionamento */
      const routeParam = `?${params.join('&')}`;

      if (location.search !== routeParam)
        history.push(location.pathname + routeParam);
    },
    [location]
  );

  const handlePageNav = useCallback(
    (step) => {
      if (catalogo && step !== 0) {
        const nextPage = shownPage - 1 + step;

        if (homeFilter.value !== null) {
          const currentPage = fmtFilterSearch[shownPage - 1];

          // console.log(currentPage);

          const indexCurrentPage = fmtFilterSearch.indexOf(
            fmtFilterSearch.find((i) => i.idPagina === currentPage.idPagina)
          );

          // console.log('indexCurrentPage...', indexCurrentPage);
          // console.log(
          //   'fmtFilterSearch[indexCurrentPage + step]...',
          //   fmtFilterSearch[indexCurrentPage + step]
          // );

          if (fmtFilterSearch[indexCurrentPage + step] === undefined) return;

          const { idPagina, niveis } = fmtFilterSearch[indexCurrentPage + step];
          const { idCatalogo } = catalogo;

          const searchLocation = [];

          niveis.forEach((itemNivel) => {
            searchLocation.push(`IDN_${itemNivel.codigo}=${itemNivel.id}`);
          });

          searchLocation.push(`IDP=${idPagina}`);

          history.push(`${idCatalogo}?${searchLocation.join('&')}`);
        }

        /** Navega para a página encontrada */
        if (
          homeFilter.value === null &&
          nextPage >= 0 &&
          nextPage < paginaCatalogo.length
        ) {
          const { idPagina, niveis } = paginaCatalogo[nextPage];

          const { idCatalogo, paginas = [] } = catalogo;

          /** verifica se a página não está filtrada */
          if (paginas.find((p) => p === idPagina)) {
            const searchLocation = [];

            /** pega parâmetros da rota */
            const paramQuery = new URLSearchParams(location.search);
            const paramRoute = {
              ...initialParams,
              ...Object.fromEntries(paramQuery),
            };

            /** processa os parâmetros de sistema, local e pesquisa caso eles existam no primeiro ciclo */
            const {
              FLT_IDS: fltIdS,
              FLT_IDL: fltIdL,
              FLT_IDE: fltIdE,
              FLT_IDA: fltIdA,
              q: fltSearch,
            } = paramRoute;
            if (fltIdS) searchLocation.push(`FLT_IDS=${fltIdS}`);
            if (fltIdL) searchLocation.push(`FLT_IDL=${fltIdL}`);
            if (fltIdE) searchLocation.push(`FLT_IDE=${fltIdE}`);
            if (fltIdA) searchLocation.push(`FLT_IDA=${fltIdA}`);
            if (fltSearch) searchLocation.push(`q=${fltSearch}`);

            /** processa os níveis da página */
            niveis.forEach((itemNivel) => {
              searchLocation.push(`IDN_${itemNivel.codigo}=${itemNivel.id}`);
            });

            /** processa página */
            searchLocation.push(`IDP=${idPagina}`);

            /** monta rota para redirecionamento */
            history.push(`${idCatalogo}?${searchLocation.join('&')}`);
          }
        }
      }
    },
    [location, catalogo, paginaCatalogo, shownPage, fmtFilterSearch, homeFilter]
  );

  const handleFiltro = useCallback(
    (key) => {
      /** pega parâmetros da rota */
      const paramQuery = new URLSearchParams(location.search);

      const params = [];
      paramQuery.forEach((param, index) => {
        if (index !== key) {
          params.push(`${index}=${param}`);
        }
      });

      const routeParam = `?${params.join('&')}`;

      history.push(location.pathname + routeParam);
    },
    [location]
  );

  const handleShowBreadcrumbs = (state) => {
    setShowBreadcrumbs(!state);
  };

  const handleBreadcrumb = useCallback(
    (data) => {
      const breadcrumbs = [];
      const url = [];

      /** pega parâmetros da rota */
      const paramQuery = new URLSearchParams(location.search);
      const paramRoute = {
        ...initialParams,
        ...Object.fromEntries(paramQuery),
      };

      /** processa os parâmetros de sistema, local e pesquisa caso eles existam */
      const {
        FLT_IDS: fltIdS,
        FLT_IDL: fltIdL,
        FLT_IDE: fltIdE,
        FLT_IDA: fltIdA,
        q: fltSearch,
      } = paramRoute;
      if (fltIdS) url.push(`FLT_IDS=${fltIdS}`);
      if (fltIdL) url.push(`FLT_IDL=${fltIdL}`);
      if (fltIdE) url.push(`FLT_IDE=${fltIdE}`);
      if (fltIdA) url.push(`FLT_IDA=${fltIdA}`);
      if (fltSearch) url.push(`q=${fltSearch}`);

      const { idCatalogo, nome, treeView } = data;

      breadcrumbs.push({
        name: nome,
        url:
          url.length > 0 ? `${idCatalogo}?${url.join('&')}` : `${idCatalogo}`,
      });

      let currNode = treeView;

      Object.keys(paramRoute).forEach((paramAtual) => {
        /** monta breadcrumb */
        if (paramAtual.startsWith('IDN_') || paramAtual.startsWith('IDP')) {
          const [, key] = paramAtual.split('_');

          /** monta url para acesso */
          url.push(`${paramAtual}=${paramRoute[paramAtual]}`);

          const itemNode = currNode.find(
            (n) =>
              n.codigo === (Number(key) || idCatalogo) &&
              n.id === Number(paramRoute[paramAtual])
          );

          if (itemNode) {
            const { descricao } = itemNode;

            breadcrumbs.push({
              name: descricao,
              url: `?${url.join('&')}`,
            });

            /** atualiza item da treeview */
            if (itemNode.itens) currNode = itemNode.itens;
          }
        }
      });

      const { name = '' } = breadcrumbs[breadcrumbs.length - 1] || {};

      return (
        <Content.ToolBar.Header>
          <h3>{name}</h3>
          {breadcrumbs.map((bread, index, array) => {
            const label =
              array.length - 1 === index ? bread.name : `${bread.name}`;

            return (
              <Link key={index} to={bread.url}>
                {label}
              </Link>
            );
          })}
        </Content.ToolBar.Header>
      );
    },
    [location]
  );

  const handleContainer = useCallback(
    (data) => {
      const { idCatalogo } = data;

      /** pega parâmetros da rota */
      const paramQuery = new URLSearchParams(location.search);

      return paramQuery.has('IDP') ? (
        <MobilePagina
          ref={paginaRef}
          id={idCatalogo}
          idPagina={Number(paramQuery.get('IDP'))}
          zoomInRef={zoomInRef}
          zoomOutRef={zoomOutRef}
          zoomResetRef={zoomResetRef}
        />
      ) : (
        <>
          {/* <WrapperShared>
            <div>
              <MdOutlineShare size={20} />
            </div>
          </WrapperShared> */}
          <MobileListagem data={data} />
        </>
      );
    },
    [location]
  );

  const Group = (props) => <components.Group {...props} />;

  const handleFiltroAsyncSelect = useCallback(
    (value) => {
      api
        .get(`/catalogo-filtro/${id}/material-catalogo`, {
          params: {
            q: value,
          },
        })
        .then((response) => {
          const { searchHandle, paginas, paginasERP } = response.data;

          // console.log(value);

          dispatch(handleHomeFilter(value, searchHandle, paginas, paginasERP));
        })
        .finally(() => {
          setValueAsyncSelect(null);
          history.push(`/catalogo.partes/${id}`);
        });

      // history.push(`/catalogo.partes/${id}`);
    },
    [id, dispatch]
  );

  useEffect(() => {
    async function addCart(output, idCatalogo) {
      return dispatch(adicionaCarrinhoArrayRequest(idCatalogo, output));
    }
    async function fetchData() {
      try {
        const response = await api.get(`app.session/${token}`);
        const { data } = response;

        const { info, idCatalogo } = data;

        const fmtOutputInfo = [];

        if (info) {
          const fmtInfo = info.split('&');

          fmtInfo.forEach((i, index) => {
            if (i.includes('IDPAG_')) {
              fmtOutputInfo.push({
                idPagina: Number(
                  fmtInfo[index].split('=')[1].replaceAll(',', '')
                ),
                idItem: String(
                  fmtInfo[index + 1].split('=')[1].replaceAll(',', '')
                ),
                idMaterial: Number(
                  fmtInfo[index + 2].split('=')[1].replaceAll(',', '')
                ),
                codimate:
                  String(
                    fmtInfo[index + 3].split('=')[1].replaceAll(',', '')
                  ) !== 'undefined'
                    ? String(
                        fmtInfo[index + 3].split('=')[1].replaceAll(',', '')
                      )
                    : null,
                quantidade: Number(
                  fmtInfo[index + 4].split('=')[1].replaceAll(',', '')
                ),
                imagemCatalogo: String(
                  fmtInfo[index + 5].split('=')[1].replaceAll(',', '')
                ),
              });
            }
          });
        }

        await addCart(fmtOutputInfo, idCatalogo);

        if (info && !sharedManagement) {
          dispatch(sharedManagementSet(true));
          toast.info(
            'Este compartilhamento inclui itens adicionados ao carrinho!'
          );
          history.push(`/carrinho`);
        }
      } catch (error) {
        toast.error(`Erro ao carregar o compartilhamento ${error}`);
      }
    }

    fetchData();
  }, [token, dispatch, sharedManagement]);

  const handleShared = useCallback(async () => {
    navigator.vibrate(200);

    const { userId, name, idEmpresa } = profile;

    const obs = `Token de compartilhamento criado pelo usuário ${userId} - ${name}`;

    const itemQtdForToken = carrinho.map((i) => {
      const {
        idItem,
        quantidadeTotal,
        idMaterial,
        idPagina: idPag,
        codimate,
        imagemCatalogo,
      } = i;

      return `&IDPAG_${idItem}=${idPag}&IDI=${idItem}&IDMAT_${idItem}=${idMaterial}&CODMAT_${idItem}=${codimate}&QTD_${idItem}=${quantidadeTotal}&IMG_${idItem}=${imagemCatalogo}`;
    });

    const { data } = await api.post(
      `empresa/${idEmpresa}/catalogo/${id}/token-acesso`,
      {
        nome: 'Compartilhamento',
        duracao: 3, // dias
        observacao: obs,
        tipo: 0,
        info: itemQtdForToken,
      }
    );

    if (!data) {
      toast.error(
        'Erro na geração do token de compartilhamento, tente novamente mais tarde!'
      );
    }

    const { nanoId } = data; // token

    const { pathname, search } = location;

    // const locationShared = window.location.href.replaceAll(
    //   `${pathname}${search}`,
    //   `/catalogo-token?token=${token}`
    // );

    const locationSharedNanoId = window.location.href.replaceAll(
      `${pathname}${search}`,
      `/catalogo-token?ass=${nanoId}`
    );

    // const fmtLocationShared = locationShared.replaceAll(',', '');
    const fmtLocationSharedNanoId = locationSharedNanoId.replaceAll(',', '');

    // const urlShared = `${fmtLocationShared}${carrinho.map((i) => {
    //   const { idItem, quantidadeTotal } = i;
    //   return `&IDI=${idItem}&IT_${idItem}QTD=${quantidadeTotal}`;
    // })}`;

    // const fmtUrlShared = urlShared.replaceAll(',', '');

    setUrlShared(fmtLocationSharedNanoId);

    if (navigator.canShare) {
      try {
        await navigator
          .share({
            title: 'CDI - Catálogo Digital Interativo',
            text: 'Catálogo disponibilizado pela Assiste Engenharia',
            url: fmtLocationSharedNanoId,
          })
          .then(() => console.log('Compartilhado com sucesso!'));
      } catch (error) {
        console.log(error);
        toast.info('Volte para compartilhar quando desejar!');
      }
    } else {
      alert('A API de compartilhamento não é suportada nesse browser.');
    }
  }, [carrinho, id, location, profile]);

  return (
    <Container>
      {catalogo && (
        <>
          <Container.Header>
            <WrapperTittleInfoSubtitle>
              <Container.WrapperBtns>
                {[
                  opTipoAcao.PLATAFORMA,
                  opTipoAcao.INTEGRACAO_CONSULTA,
                ].includes(action) && (
                  <Container.Header.BtnBack
                    title="Voltar para a Home"
                    onClick={() => history.push('/')}
                  >
                    <MdHome size={25} />
                  </Container.Header.BtnBack>
                )}
                {empresa.idEmpresa === opEmpresa.DMB && (
                  <Container.Header.DownloadPdf
                    title="Download catálogo pdf completo"
                    href={catalogoPdf}
                    target="_blank"
                  >
                    <MdPictureAsPdf size={25} />
                  </Container.Header.DownloadPdf>
                )}
              </Container.WrapperBtns>

              <WrapperTitleInfo>
                <Container.Header.Title>{catalogo.nome}</Container.Header.Title>
                <span>
                  {catalogo.arquivo.fabricante.descricao}
                  {' - '}
                  {catalogo.arquivo.modelos.map((item) => item.descricao)}
                </span>
              </WrapperTitleInfo>
              {/* <WrapperShared>
                <div>
                  <MdOutlineShare size={20} />
                </div>
              </WrapperShared> */}
            </WrapperTittleInfoSubtitle>

            {[opTipoAcao.INTEGRACAO_CATALOGO].includes(action) &&
              [opTipoAcesso.CLIENTE].includes(Number(adm)) &&
              signed && (
                <Container.Header.BtnFavorite title="Solicitar ao fornecedor para manter o acesso ao catálogo">
                  <MdStarOutline size={25} />
                </Container.Header.BtnFavorite>
              )}
            <Container.Header.Search>
              <Form.Row>
                <Form ref={formRef}>
                  {catalogo.arranjoPagina && (
                    <AsyncSelect
                      id="idArranjo"
                      name="idArranjo"
                      placeholder="Digite para prequisar um arranjo..."
                      isClearable
                      cacheOptions
                      defaultOptions
                      loadOptions={(value, cb) => getArranjo({ id, value }, cb)}
                      onChange={(data) => handleSerieArranjo(data)}
                      width={270}
                    />
                  )}
                  <AsyncSelect
                    id="idMaterial"
                    name="idMaterial"
                    placeholder="Digite um termo, partnumber ou código ERP para pesquisar..."
                    isClearable
                    cacheOptions
                    defaultOptions
                    loadOptions={(value, cb) => {
                      setValueAsyncSelect(value);
                      getMaterial({ id, value }, cb);
                    }}
                    component={{ Group }}
                    onChange={(data) => handlePagina(data)}
                    formatOptionLabel={(option, { context }) => {
                      const imageUrl = option.label.caminho;

                      // Apenas se o Select estiver aberto (context === 'menu')
                      return (
                        <div className="container-async-select">
                          {context === 'menu' ? (
                            <>
                              <div
                                className="hover-container-async-select"
                                // onMouseEnter={() => setIsHovered(option)}
                                // onMouseLeave={() => setIsHovered(null)}
                              >
                                <img
                                  id="img-async-select"
                                  src={imageUrl}
                                  alt={option.label.descricaoPagina}
                                />
                                {/* Renderizar a imagem fora do menu usando o Portal */}
                                {/* <HoverImagePortal
                                  option={option}
                                  src={imageUrl}
                                  alt={option.label.descricaoPagina}
                                  isVisible={isHovered === option}
                                /> */}
                                {/* <img
                                  className="hover-image-async-select"
                                  src={imageUrl}
                                  alt={option.label.descricaoPagina}
                                /> */}
                              </div>
                              <div>
                                <strong id="grey-bold-async-select">
                                  {option.label.partnumber ||
                                    option.label.codimate}{' '}
                                  - {option.label.descricao}{' '}
                                </strong>
                                <div id="grey-italic-async-select">
                                  {option.label.descricaoPagina}{' '}
                                  <strong id="black-bold-async-select">
                                    {' '}
                                    |{' '}
                                  </strong>
                                  {option.label.fmtPaginaCatalogoNivel}
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <strong id="grey-bold-async-select">
                                {option.label.partnumber ||
                                  option.label.codimate}{' '}
                                - {option.label.descricao}
                                <strong id="black-bold-async-select">
                                  {' '}
                                  |{' '}
                                </strong>
                              </strong>
                              <span id="grey-italic-async-select">
                                {option.label.descricaoPagina}{' '}
                                <strong id="black-bold-async-select">
                                  {' '}
                                  |{' '}
                                </strong>
                                {option.label.fmtPaginaCatalogoNivel}
                              </span>
                            </>
                          )}
                        </div>
                      );
                    }}
                    styles={{
                      control: (base) => ({
                        ...base,
                        height: '40px',
                        borderRadius: '5px 0 0 5px',
                      }),
                      menu: (base) => ({
                        ...base,
                        backgroundColor: '#fafafa',
                        // width: 450,
                        minHeight: 550,
                      }),
                      menuList: (base) => ({
                        ...base,
                        minHeight: 500,
                        maxHeight: 550,
                      }),
                      groupHeading: (base) => ({
                        ...base,
                        color: '#525252',
                        borderBottom: '1px solid #cbd5e1',
                      }),
                      clearIndicator: (base) => ({
                        ...base,
                        '&:hover': {
                          color: '#dc2626',
                        },
                      }),
                      option: (base, state) => ({
                        ...base,
                        backgroundColor: state.isFocused
                          ? '#e5e7eb'
                          : '#f8fafc',
                        '&:hover': {
                          backgroundColor: '#e5e7eb',
                        },
                      }),
                    }}
                  />
                </Form>
                <BtnFilter
                  title="Filtrar páginas pelo termo digitado"
                  onClick={() => {
                    handleFiltroAsyncSelect(valueAsyncSelect);
                    // setValueAsyncSelect(null);
                  }}
                >
                  <MdSearch size={20} />
                </BtnFilter>
              </Form.Row>
              <WrapperFilterContent>
                {homeFilter.value && (
                  <div>
                    <h5>Filtrado por: </h5>
                    <span>{homeFilter.value}</span>
                    <button
                      type="button"
                      title="Limpar filtro"
                      onClick={() => {
                        dispatch(handleHomeFilterClear());
                        history.push(`/catalogo.partes/${id}`);
                      }}
                    >
                      <MdClose size={20} />
                    </button>
                  </div>
                )}
              </WrapperFilterContent>
            </Container.Header.Search>
          </Container.Header>

          {menuHidden && (
            <Modal width={95} height={95} onClose={handleTreeView}>
              <Menu.Content.TreeView>
                <Menu.Content.Filter>
                  {catalogo.sistema && (
                    <span>
                      {catalogo.sistema.descricao}
                      <button
                        type="button"
                        onClick={() =>
                          handleFiltro('FLT_IDS', catalogo.sistema.codiSist)
                        }
                      >
                        <MdClose size={12} />
                      </button>
                    </span>
                  )}

                  {catalogo.local && (
                    <span>
                      {catalogo.local.descricao}
                      <button
                        type="button"
                        onClick={() =>
                          handleFiltro('FLT_IDL', catalogo.local.codiLoca)
                        }
                      >
                        <MdClose size={12} />
                      </button>
                    </span>
                  )}
                </Menu.Content.Filter>
                <TreeView data={catalogo} />
              </Menu.Content.TreeView>
            </Modal>
          )}

          <Main>
            <Main.Container>
              <Main.Wrapper>
                <Content>
                  <Content.BtnWrapper ref={zoomResetRef}>
                    <ContentToolBarHeaderButton
                      onClick={() => handleShowBreadcrumbs(showBreadcrumbs)}
                    >
                      {!showBreadcrumbs ? (
                        <MdKeyboardArrowDown size={16} />
                      ) : (
                        <MdKeyboardArrowUp size={16} />
                      )}
                    </ContentToolBarHeaderButton>
                    <BtnTreeView onClick={() => handleTreeView()}>
                      <MdListAlt size={25} />
                    </BtnTreeView>
                    {profile.userId &&
                      profile.name &&
                      (empresa.idEmpresa === opEmpresa.ASSISTE ||
                        empresa.idEmpresa === opEmpresa.DMB) && (
                        <WrapperShared onClick={() => handleShared()}>
                          <MdOutlineShare size={20} />
                        </WrapperShared>
                      )}
                    {/* <BtnShare
                      id="flutuante-share"
                      onClick={() => console.log('flutuante-share')}
                    >
                      <MdOutlineShare size={20} />
                    </BtnShare> */}
                  </Content.BtnWrapper>

                  {catalogo && showBreadcrumbs && handleBreadcrumb(catalogo)}

                  {controle && showBreadcrumbs && (
                    <Content.ToolBar.DrawControl>
                      <button
                        type="button"
                        onClick={debounce(() => handlePageNav(-1), 100)}
                      >
                        <MdChevronLeft size={20} />
                      </button>
                      <span>{shownPage}</span>
                      <button
                        type="button"
                        onClick={debounce(() => handlePageNav(1), 100)}
                      >
                        <MdChevronRight size={20} />
                      </button>
                    </Content.ToolBar.DrawControl>
                  )}

                  {catalogo && handleContainer(catalogo)}
                </Content>
              </Main.Wrapper>
            </Main.Container>
          </Main>
        </>
      )}
    </Container>
  );
}
