import styled, { css } from 'styled-components';
import { darken, lighten } from 'polished';
import { Form as WebForm } from '@unform/web';
import FormControlLabel from '@mui/material/FormControlLabel';

const handleCorTipo = (id) => {
  switch (id) {
    case 1:
      return 'rgba(83,128,97,.9)';
    case 2:
      return 'rgba(230,222,42,.9)';
    case 3:
      return 'rgba(84,133,247,.9)';
    case 4:
      return 'rgba(176,24,16,.9)';
    default:
      return 'rgba(204,204,204,.9)';
  }
};

const handleCorSituacao = (id) => {
  switch (id) {
    case 1:
      return 'rgba(230,222,42,.9)';
    case 2:
      return 'rgba(83,128,97,.9)';
    default:
      return 'rgba(204,204,204,.9)';
  }
};

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* padding: 20px 0; */
  height: 100%;
  width: 100%;
  /* max-height: inherit; */
  max-width: ${(props) => (props.width ? `${props.width}px` : '1600px')};
  overflow-y: auto;
  margin: 0 auto;

  ${(props) =>
    props.withBorder &&
    css`
      box-shadow: 0px 0px 10px #00000033;
      border-radius: 10px;
      background: #fff;
      margin: 34px auto;
      padding: 40px 60px;
      height: auto;
    `}
`;

export const Form = styled(WebForm)`
  /* flex: 1; */
  width: 100%;
  display: flex;
  flex-direction: ${(props) => (props.row ? 'row' : 'column')};
  justify-content: center;
  /* height: 100%; */

  h3 {
    padding: 5px 0 10px;
  }

  & > footer {
    display: flex;
    justify-self: flex-end;
    border-top: 1px solid #dbe1e9;
    padding-top: 10px;
    margin-top: 20px;

    align-items: center;
    justify-content: flex-end;

    & > button {
      /* border: 1px solid #ccc; */
      border-radius: 5px;
      padding: 10px 20px;
      font-size: 16px;
      margin-top: 10px;
      min-width: 150px;
      width: 150px;
      background: none;
      border: none;
      color: #a0a9b9;

      &:hover {
        color: ${darken(0.05, '#a0a9b9')};
      }

      & + button {
        margin-left: 10px;
      }
    }
  }
`;

Form.Title = styled.h1`
  flex: 1;
  font-size: 22px;

  padding: 10px 0 15px;
`;

Form.Detail = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #dbe1e9;
  border-radius: 5px;
  padding: 20px 30px;
`;

Form.Separate = styled.div`
  border-bottom: 1px solid #dbe1e9;
  min-height: ${(props) => (props.height ? `${props.height}px` : `1px`)};

  ${(props) =>
    props.withFlex &&
    css`
      flex: 1;
    `}
`;

Form.Row = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border: 0;
  padding: 0;
  margin: 0;

  @media (max-width: 600px) {
    flex-direction: row;
    align-items: stretch;
  }

  ${(props) =>
    props.justifyCenter &&
    css`
      justify-content: space-evenly;
    `}

  ${(props) =>
    props.justifyBetween &&
    css`
      justify-content: space-between;
    `}

  ${(props) =>
    props.justifyEnd &&
    css`
      justify-content: flex-end;
    `}

  ${(props) =>
    props.alignCenter &&
    css`
      align-items: center;
    `}

  ${(props) =>
    props.alignEnd &&
    css`
      align-items: flex-end;
    `}

  ${(props) =>
    props.withBorder &&
    css`
      border-top: 1px solid #dbe1e9;
      padding-top: 10px;
      margin-top: 10px;
    `}

  & > div {
    padding: 5px;

    @media (max-width: 600px) {
      padding: 5px 0 0 0;
    }

    :first-child {
      padding-left: 0;
    }

    :last-child {
      padding-right: 0;
    }
  }

  &#tipoPagina {
    justify-content: end;
  }
`;

Form.Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${(props) =>
    props.justifyCenter &&
    css`
      justify-content: space-evenly;
    `}

  ${(props) =>
    props.justifyEnd &&
    css`
      justify-content: flex-end;
    `}

  ${(props) =>
    props.alignCenter &&
    css`
      align-items: center;
    `}

  ${(props) =>
    props.alignEnd &&
    css`
      align-items: flex-end;
    `}

  ${(props) =>
    props.withPadding &&
    css`
      padding: 10px;
    `}

  ${(props) =>
    props.withBorder &&
    css`
      border-top: 1px solid #dbe1e9;
      padding-top: 10px;
      margin-top: 10px;
    `}
`;

Form.Row.PageType = styled.span`
  border-radius: 5px;
  padding: 5px 10px;
  text-align: center;
  font-weight: bold;
  width: 100px;
  min-width: 100px;

  ${({ type }) =>
    type &&
    css`
      color: ${handleCorTipo(type)};
      border: 2px solid ${handleCorTipo(type)};
    `}

  & + span {
    margin-left: 5px;
  }
`;

Form.Row.PageStatus = styled.span`
  border-radius: 5px;
  padding: 5px 10px;
  text-align: center;
  font-weight: bold;
  width: 100px;
  min-width: 100px;

  ${({ status }) =>
    status &&
    css`
      color: ${handleCorSituacao(status)};
      border: 2px solid ${handleCorSituacao(status)};
    `}

  & + span {
    margin-left: 5px;
  }
`;

Form.Column.Kind = styled.span`
  background: #dbe1e9;
  border: 1px solid #dbe1e9;
  border-radius: 5px;
  color: #fff;
  /* font-weight: bold; */
  width: 100%;
  padding: 5px 10px;

  ${(props) =>
    props.isAdded &&
    css`
      background: #538061;
    `};

  ${(props) =>
    props.isRemoved &&
    css`
      background: #de3b3b;
    `}

  ${(props) =>
    props.isPending &&
    css`
      background: #e6de2a;
    `}
`;

Form.NotFound = styled.span`
  display: block;
  width: 100%;
  text-align: center;

  font-size: 14px;
  font-style: italic;
  color: rgba(44, 64, 90, 0.5);
  padding: 40px 0;
`;

Form.Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 10px 0 2px;

  ${(props) =>
    props.withStart &&
    css`
      justify-content: flex-start;
    `}

  ${(props) =>
    props.withDirectNav &&
    css`
      justify-content: space-between;
    `}

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: 0;
    border-radius: 5px;
    min-height: 42px;
    min-width: 150px;
    color: #a0a9b9;
    transition: all 1s;

    & + button {
      margin-left: 20px;
    }

    & > svg {
      margin-right: 5px;
    }

    &#btn-submit {
      color: #fff;
      background: #ff6d3c;

      &:hover {
        background: ${darken(0.1, '#ff6d3c')};
      }
    }

    &#btn-aprovar {
      color: #fff;
      background: #22c55e;
      font-weight: bold;

      &:hover {
        background: ${darken(0.1, '#22c55e')};
      }
    }

    &#btn-reprovar {
      color: #fff;
      background: #ff6d3c;
      font-weight: bold;

      &:hover {
        background: ${darken(0.1, '#ff6d3c')};
      }
    }

    &#btn-cancel {
      &:hover {
        color: ${darken(0.1, '#a0a9b9')};
      }
    }
  }
`;

Form.Footer.Link = styled.button`
  min-width: 20px !important;

  :hover {
    color: ${darken(0.05, '#1789dd')};
  }
`;

Form.ControlWrapper = styled.div`
  display: flex;
  /* flex-direction: row; */
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
`;

Form.CardWrapper = styled.div`
  display: flex;
  align-items: center;
`;

Form.ControlLabel = styled(FormControlLabel)`
  flex: 1;
`;

Form.Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 10px;
  row-gap: 15px;
`;

export const Row = styled.div`
  flex: 1;
  display: flex;

  /** Adiciona borda */
  border-top: ${(props) => (props.borderTop ? '1px solid #dbe1e9' : '0')};
  padding-top: ${(props) => (props.borderTop ? '10px' : '0')};
  margin-top: ${(props) => (props.borderTop ? '10px' : '0')};

  justify-content: ${(props) => (props.alignEnd ? 'flex-end' : 'flex-start')};
  align-items: ${(props) => (props.alingItens ? 'center' : 'flex-start')};

  & > div {
    padding: 10px 20px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  & > span {
    margin-left: -10px;
    color: #444;
  }

  ${(props) =>
    props.customPagination &&
    css`
      align-items: center;

      & > div {
        padding: 0 20px;
      }
    `}
`;

export const Column = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: fit-content;

  /** Adiciona borda */
  border-top: ${(props) => (props.borderTop ? '1px solid #dbe1e9' : '0')};
  padding-top: ${(props) => (props.borderTop ? '10px' : '0')};
  margin-top: ${(props) => (props.borderTop ? '10px' : '0')};
`;

export const RowFooter = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: ${(props) => (props.alignStart ? 'flex-start' : 'flex-end')};
  margin-top: 30px;
  padding: 20px 0;
  border-top: 1px solid #dbe1e9;

  #btn-submit {
    color: #fff;
    background: #ff6d3c;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 5px;
    min-height: 42px;
    min-width: 150px;

    & + button {
      margin-left: 10px;
    }

    & > svg {
      margin-right: 5px;
    }

    &:hover {
      background: ${darken(0.05, '#ff6d3c')};
    }
  }

  #btn-cancel {
    background: none;
    border: none;
    color: #a0a9b9;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 5px;
    min-height: 42px;
    min-width: 150px;

    & + button {
      margin-left: 10px;
    }

    & > svg {
      margin-right: 5px;
    }

    &:hover {
      color: ${darken(0.05, '#a0a9b9')};
    }
  }
`;

export const LinkButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  color: #1789dd;
  padding: 10px 20px;
  min-width: 120px;
  transition: color 1s;

  & + button {
    margin-left: 5px;
  }

  :hover {
    color: ${darken(0.05, '#1789dd')};
  }

  > svg {
    margin-right: 5px;
  }
`;

export const ModeloButton = styled.button`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 1px solid #a0a9b9;
  color: #a0a9b9;
  border-radius: 5px;
  padding: 10px 20px;
  min-width: 120px;
  transition: all 0.3s;

  :hover {
    color: #fff;
    border-color: #1789dd;
    background: ${lighten(0.08, '#1789dd')};
  }

  > svg {
    margin-right: 5px;
  }
`;

export const InputWrapper = styled.div`
  display: ${(props) => (props.isHidden ? 'none' : 'flex')};
  flex-direction: ${(props) => props.flexDirection};
  padding: 5px 0;
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
  max-width: ${(props) => (props.width ? `${props.width}px` : '100%')};

  @media (max-width: 600px) {
    font-size: 10px;
    width: 100%;
    max-width: 100%;
  }

  ${(props) =>
    props.height &&
    css`
      height: ${props.height}px;
    `}

  label {
    display: block;
    text-align: left;
    margin-bottom: 5px;
    color: #444;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    width: 100%;
  }

  input {
    display: block;
    border: ${(props) => (props.custom ? 'none' : '1px solid #cbd0d8')};
    border-radius: 5px;
    color: #444;
    font-size: 16px;
    padding: 10px 15px;
    width: 100%;

    &::placeholder {
      color: #79869e;
      font-size: 14px;
    }

    &:disabled {
      background: #f9fbff;
    }
  }

  span.error {
    font-size: 11px;
    font-weight: bold;
    color: #de3b3b;
    padding-top: 2px;
  }

  ${(props) =>
    props.customPagination &&
    css`
      input {
        padding: 2px 15px;
      }
    `}
`;

export const FileWrapper = styled.div`
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #cbd0d8;
  border-radius: 5px;
  padding: 10px 20px;
  width: 100%;
  max-width: 500px;
  height: 100%;

  ${(props) =>
    props.withBackground &&
    css`
      background: url(${props.withBackground}) no-repeat center;
      background-size: contain;
    `}

  > div {
    display: flex;
    flex-direction: column;
    padding: 30px 20px;

    text-align: center;

    ${(props) =>
      props.withBackground &&
      css`
        background: rgba(255, 255, 255, 0.8);
        border-radius: 5px;
      `}

    > label {
      margin: 10px auto 0;
      text-align: center;
      border-radius: 5px;
      padding: 10px 20px;
      background: #ff6d3c;
      color: #fff;
      font-weight: normal;
      transition: background 1s;
      max-width: 400px;

      :hover {
        background: ${darken(0.1, '#ff6d3c')};
      }

      > input[type='file'] {
        display: none;
      }
    }
  }
`;

export const ImgFileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: ${(props) => `${props.height}px`};
  max-width: ${(props) => `${props.width}px`};

  > img {
    border: 1px dashed #cbd0d8;
    padding: 5px;
    object-fit: contain;
    height: 100%;
    width: 100%;
  }
`;

ImgFileWrapper.Control = styled.div`
  display: flex;
  padding-top: 5px;

  > label {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid #ff6d3c;
    color: #ff6d3c;
    transition: background 1s;
    height: 25px;
    width: 25px;

    :hover {
      background: ${darken(0.1, '#ff6d3c')};
      color: #fff;
    }

    > input[type='file'] {
      display: none;
    }
  }

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    color: #cbd0d8;
    border: 1px solid #cbd0d8;
    border-radius: 5px;
    margin-left: 5px;
    transition: background 1s;
    height: 25px;
    width: 25px;

    :hover {
      background: ${darken(0.1, '#de3b3b')};
      border: 1px solid #de3b3b;
      color: #fff;
    }
  }
`;

ImgFileWrapper.Preview = styled.div`
  display: flex;
  position: relative;

  > img {
    height: auto;
    width: 180px;
  }

  > button {
    position: absolute;
    right: 0;
    top: 0;

    background: none;
    border: 0;
  }
`;

export const ViewerWrapper = styled.div`
  display: ${(props) => (props.isHidden ? 'none' : 'flex')};
  position: relative;
  flex-direction: ${(props) => props.flexDirection};
  padding: 10px 0;
  width: 100%;
  max-width: ${(props) => (props.width ? `${props.width}px` : '100%')};

  ${(props) =>
    props.asRow &&
    css`
      align-items: center;
      flex-direction: row;
      width: auto;
    `}

  button.attach {
    display: flex;
    align-items: center;
    border: 0;
    background: none;
    color: #0030cf;
    text-align: left;
    padding: 0;
    transition: color 1s;

    > span {
      margin-left: 5px;
      max-width: 180px;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    :hover {
      color: ${darken(0.05, '#0030cf')};
      text-decoration: underline;
    }
  }

  label {
    display: block;
    text-align: left;
    margin-bottom: ${(props) => (props.strong ? '12px' : '5px')};

    color: ${(props) => props.theme.colorRequestName};
    font-size: 14px;
    width: 100%;

    ${(props) =>
      props.asRow &&
      css`
        margin: 0;
        width: 150px;
      `}
  }

  input {
    display: block;
    border: none;
    background: none;

    color: ${(props) => props.theme.colorUserName};
    font-size: ${(props) => (props.strong ? '20px' : '15px')};
    font-weight: ${(props) => (props.strong ? 'bold' : 'normal')};
    width: 100%;

    ${(props) =>
      props.asRow &&
      css`
        width: auto;
      `}
  }

  textarea {
    display: block;
    border: none;
    background: none;
    color: #444;
    font-size: 15px;
    width: 100%;
    resize: none;
    white-space: pre-line;
  }
`;

export const CheckWrapper = styled.div`
  padding: 10px 0;

  label {
    display: flex;
    align-items: center;
    color: #444;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;

    input {
      margin-right: 5px;
      color: #999;
    }
  }

  span.error {
    font-size: 11px;
    font-weight: bold;
    color: #de3b3b;
    padding-top: 2px;
  }
`;

export const TextAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  width: 100%;
  max-width: ${(props) => (props.width ? `${props.width}px` : '100%')};

  label {
    display: block;
    text-align: left;
    margin-bottom: 5px;
    color: #444;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    width: 100%;
  }

  textarea {
    display: block;
    border: 1px solid #ddd;
    border-radius: 4px;
    color: #444;
    font-size: 16px;
    padding: 14px 15px;
    width: 100%;
    resize: none;

    &::placeholder {
      color: #999;
    }
  }

  span.error {
    font-size: 11px;
    font-weight: bold;
    color: #de3b3b;
    padding-top: 2px;
  }
`;

export const FormContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
